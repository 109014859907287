<template>
<span>
    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="600px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <img v-if="prod_co_img" :src="`${base_url}upload/product_color/${prod_co_img}?${uuid}`" class="foto-ampliada" />
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Cores Produto</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click="atualizarDados">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'product.colors.add'}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Nova Cor
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadProductColors" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.prod_co_img`]="{ item }">
                <td width="15%" v-if="item.prod_co_img"><img :src="`${base_url}upload/product_color/${item.prod_co_img}?${uuid}`" class="avatar" @click.prevent="ampliarFoto(item.prod_co_img)"></td>
                <td width="15%" v-if="!item.prod_co_img"><img :src="`${base_url}upload/logo.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.prod_co_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.prod_co_status" @change="onChangeEventHandlerStatus(item.prod_co_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editProductColors(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteProductColors(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="ampliarFoto(item.prod_co_img)" v-on="on">
                            <v-icon dark class="mdi mdi-image-search"></v-icon>
                        </v-btn>
                    </template>
                    <span>Ampliar Imagem</span>
                </v-tooltip>

            </template> <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    uuid
} from "vue-uuid";
import {
    URL_BASE
} from "../../../config/configs";

export default {
    name: "ProductColorComponent",
    created() {
        this.$store.dispatch("loadProductColors");
    },
    computed: {
        loadProductColors() {
            if (this.$store.state.productcolors.items.data !== null) {
                return this.$store.state.productcolors.items.data;
            }
        }
    },
    props: {
        prod_co_id: {
            require: true
        }
    },
    data() {
        return {
            dialog_foto: "",
            prod_co_img: null,
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    "10": -1
                }
            ],
            headers: [{
                    text: "Cor",
                    align: "left",
                    sortable: false,
                    value: "prod_co_img"
                },
                {
                    text: "Nome",
                    value: "prod_co_nome",
                    sortable: false
                },
                {
                    text: "Hexadecimal",
                    value: "prod_co_hexa",
                    sortable: false
                },
                {
                    text: "UInt",
                    value: "prod_co_uint",
                    sortable: false
                },
                {
                    text: "Código",
                    value: "prod_co_cod",
                    sortable: false
                },
                {
                    text: "Código",
                    value: "prod_co_cod",
                    sortable: false
                },
                {
                    text: "Tipo",
                    value: "prod_co_tipo",
                    sortable: false
                },
                {
                    text: "Status",
                    value: "prod_co_status",
                    sortable: false
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            base_url: URL_BASE,
            uuid: uuid.v1()
        };
    },
    methods: {
        atualizarDados() {
            this.$store.dispatch("loadProductColors", this.prod_id);
        },
        editProductColors(item) {
            this.$router.push({
                name: "product.colors.edit",
                params: {
                    prod_co_id: item.prod_co_id
                }
            });
        },
        deleteProductColors(item) {

            this.$swal({
                title: "Você tem certeza?",
                text: "Você não poderá reverter isso",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D32F2F",
                cancelButtonColor: "#1976D2",
                confirmButtonText: "Sim, Exclua!"
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch("destroyProductColor", item.prod_co_id)
                        .then(response => {
                            if (response) {
                                this.atualizarDados();
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: "Opssss!",
                                    text: "Erro ao excluir dados",
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Opssss!",
                                text: "Algo errado aconteceu!",
                                showConfirmButton: true,
                                timer: 6000
                            });
                        });
                }
            });

        },
        onChangeEventHandlerStatus(prod_co_id, value) {
            this.$store
                .dispatch("updateProductColorStatus", {
                    prod_co_id: prod_co_id,
                    prod_co_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        ampliarFoto(foto) {
            this.prod_co_img = foto;
            this.dialog_foto = true;
        }
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%;
}
</style>
